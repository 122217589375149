.bingo {
    /*  Ball shizzle */

    @-moz-document url-prefix() {
        #ball {
            height: 150px;
        }
    }

    // #currentBall {
    //     box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3),
    //         inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);
    // }

    .blue {
        background: radial-gradient(circle at 20% 20%, #5b8af7, #2155cd);
    }

    .blue .content {
        border: 0.15rem solid #5b8af7;
    }

    .red {
        background: radial-gradient(circle at 20% 20%, #ff0000, #910000);
    }

    .red .content {
        border: 0.15rem solid #ff0000;
    }

    .white {
        background: radial-gradient(circle at 20% 20%, #ffffff, #b6b6b6);
    }

    .white .content {
        border: 0.15rem solid #ff0000;
    }

    .green {
        background: radial-gradient(circle at 20% 20%, #69eb7b, #32ad43);
    }

    .green .content {
        border: 0.15rem solid #69eb7b;
    }

    .yellow {
        background: radial-gradient(circle at 20% 20%, #ffe52a, #f7bd00);
    }

    .yellow .content {
        border: 0.15rem solid #f7bd00;
    }

    .black {
        background: radial-gradient(circle at 20% 20%, #e1e1e1, #2d2d2d);
    }

    .black .content {
        border: 0.15rem solid #2d2d2d;
    }

    .purple {
        background: radial-gradient(circle at 20% 20%, #b072b3, #8c4ed3);
    }

    .purple .content {
        border: 0.15rem solid #b072b3;
    }

    .cyan {
        background: radial-gradient(circle at 20% 20%, #76dad7, #2e9b98);
    }

    .cyan .content {
        border: 0.15rem solid #2e9b98;
    }

    .orange {
        background: radial-gradient(circle at 20% 20%, #dab876, #d59823);
    }

    .orange .content {
        border: 0.15rem solid #d59823;
    }

    /* End Ball Shizzle */

    .middleDiv {
        .numbersTable {
            width: 800px;
            margin: 0 auto;
            font-weight: 500;
            margin-bottom: 40px;

            tr {
                line-height: 3px;

                td {
                    border: 1px solid #2d2d2d;
                    cursor: default;

                    &.selected {
                        background-color: #635f5f;
                        opacity: 0.75;
                    }
                }
            }
        }

        .blinking {
            @keyframes blinkingText {
                0% {
                    color: #000;
                }
                49% {
                    color: #000;
                }
                60% {
                    color: transparent;
                }
                99% {
                    color: transparent;
                }
                100% {
                    color: #000;
                }
            }

            animation: blinkingText 1.2s 5;
        }

        .nickname {
            font-size: 3em;
        }

        .accordion {
            cursor: pointer;

            .autoSection {
                display: flex;
                margin: 50px;

                .bingoTimer {
                    margin: auto;

                    .timer {
                        cursor: default;
                        display: contents;
                    }
                }
            }
        }
    }

    .rightDiv {
        #ballcount {
            position: absolute;
            bottom: -2rem;
            color: #e63935;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;

            &:before {
                content: "Call #";
                color: #4e95d3;
                margin-right: 0.25em;
            }
        }

        #ball {
            width: 100%;
            display: flex;
            border-radius: 100%;
            position: relative;
            align-items: center;
            justify-content: center;
            max-width: 150px !important;
            margin: 10px auto;
            box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgba(255, 255, 255, 0.3),
                inset -0.25rem -0.25rem 1.5rem 0.5rem rgba(0, 0, 0, 0.4);

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            .content {
                text-align: center;
                border-radius: 100%;
                background: white;
                position: relative;
                min-width: 1rem;
                padding: 25%;
                box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(0, 0, 0, 0.16), 0 0 0 0.25rem white;
                background: radial-gradient(circle at 20% 20%, white, #efefef);

                span {
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    text-align: center;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: inline-flex;
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 1;
                }

                img {
                    padding: 0.25rem;
                }
            }
        }
    }
}
