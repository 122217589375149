.outerDiv {
    display: flex;
    margin-top: 75px;
    text-align: center;
    padding: 30px;

    .leftDiv,
    .middleDiv,
    .rightDiv {
        flex: 1; /*grow*/
    }
}