@use "navbar.scss";
@use "home.scss";
@use "./picturePages";
@use "game.scss";
@use "countdown.scss";
@use "bingo.scss";
@use "timer.scss";

html {
    background-color: #e2e2e2;
    margin: 0;
    padding: 0;
    height: 100%;
    margin: 0;
    font: 400 15px/1.8 "Lato", sans-serif;
    color: #777;

    body {
        
        background-color: #fff;
        font-size: 1rem;
        font-family: "Segoe UI", Verdana, Helvetica, Sans-Serif;

        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        #root .mainDiv {
            h6 {
                color: white;
                font-family: cursive;
            }

            #body {                
                .pictureDiv {
                    margin: 30px 200px;
                }
            }
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
