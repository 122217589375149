.main-img,
.middle-img,
.bottom-img {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-img {
  background-image: url(../images/RIAT.jpg);
  min-height: 100%;
  height: 100vh;
}

.middle-img {
  background-image: url(../images/fayanddanieldec2004.bmp);
  min-height: 750px;
  height: 100vh;
}

.bottom-img {
  background-image: url(../images/Tonka3.jpg);
  min-height: 750px;
  height: 100vh;
}

.caption {
  position: absolute;
  left: 0;
  top: 80%;
  width: 100%;
  text-align: center;
  color: #000;
  opacity: 0.4;
}

.caption span.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .main-img,
  .middle-img,
  .bottom-img {
    background-attachment: scroll;
  }
}
