fieldset {
  border: 1px solid #e5e5e5;
  padding: 2em 0 1em;
  margin-top: 2em;
  position: relative;
  border-radius: 10px;
  
  legend {
    border: 0;
    background: #fff;
    width: auto;
    transform: translateY(-50%);
    position: absolute;
    top: 0; left: 1em;
    padding: 0 .5em;
    border-radius: 5px;
  }

  #mainContent {
    padding-bottom: 20px;
    margin-top: 30px;
  
    #container {
      .clear {
        text-align: center;
      }
    
      img {
        padding-right: 20px;
        vertical-align: baseline;
        cursor: pointer;
        width: 125px;
        opacity: 0.5;
    
        &:hover {
          position:relative;
          width:200px;
          height:auto;
          display:inline-block;
          z-index:999;
          text-align: center;
          opacity: 1;
        }
      }
    }
  }
}