nav {
  background: #232323;
  height: 100%;
  left: -280px;
  position: fixed;
  width: 280px;
  z-index: 800;
  transition: all 1.5s ease-in-out;

  &:hover {
    left: 0;
  }

  &:after {
    background: #313737;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: -3px;
    top: 0;
    width: 3px;
    z-index: 800;
  }

  .menuHover {
    color: #a4a4a4;
    font-size: 10px;
    height: 100%;
    line-height: 30px;
    padding-left: 10px;
    right: -30px;
    top: 0px;
    text-align: center;
    text-transform: uppercase;
    width: 40px;
    position: absolute;
    cursor: pointer;

    img {
      background: no-repeat center center;
      display: block;
      height: 16px;
      left: 20px;
      margin-top: -8px;
      position: absolute;
      text-indent: -9999px;
      top: 50%;
      width: 17px;
      z-index: 800;
    }
  }

  .userSection {
    .inliner {
      display: inline-block;
      margin-right: 20px;
    }

    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  .menu {
    text-align: center !important;
    text-transform: uppercase;

    > div {
      clear: both;
      padding-bottom: 15px;
      zoom: 1;
      *zoom: 1;

      :before {
        content: "";
        display: table;
      }

      :after {
        content: "";
        display: table;
        clear: both;
      }
    }

    a:hover {
      color: #82eea5 !important;
    }

    a.big,
    header.big {
      color: #ffffff;
      font-weight: bold;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      :hover {
        opacity: 0.5;
      }
    }

    ul {
      margin: 15px 0 0;
      padding: 0;

      > li {
        font-size: 10px;
        line-height: 130%;
        margin-bottom: 3px;
      }
    }

    .list > ul {
      display: none;
    }

    .list:hover > ul {
      display: block;

      a {
        color: #77a0bd;

        &:hover {
          color: #82eea5;
          font-size: 16px;
        }
      }
    }

    .bottomarea {
      bottom: 0;
      color: #a4a4a4;
      left: 0;
      padding-bottom: 20px;
      position: absolute;
      text-align: center;
      width: 100%;

      a.btn_lang {
        font-size: 8px;
        text-transform: uppercase;
      }

      .copyright {
        font-size: 10px;
      }

      .social ul {
        margin: 0 0 10px 0;
        padding: 0;
        zoom: 1;
        *zoom: 1;

        :before {
          content: "";
          display: table;
        }

        :after {
          content: "";
          display: table;
          clear: both;
        }

        li {
          display: inline-block;
          margin-left: 2px;

          :first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .table {
    .tablecell {
      .centred.menu {
        .list {
          ul {
            list-style: none;
            list-style-image: none;

            .ul1 {
              display: none;
              height: 51px;
              padding-top: 0px;
              margin-top: 15px;
              padding-bottom: 0px;
              margin-bottom: 0px;
            }

            .ul2 {
              display: none;
              height: 102px;
              padding-top: 0px;
              margin-top: 15px;
              padding-bottom: 0px;
              margin-bottom: 0px;
            }
          }

          ol {
            list-style: none;
            list-style-image: none;
          }
        }
      }
    }
  }
}