.countdown {
    &.outerDiv {
        .leftDiv {
            .newGameBtn {
                height: 300px;
            }

            .gameSelected {
                background-color: #10506a !important;
                border: none;
            }

            .gameUnselected {
                background-color: #ccd1d3;
                border: none;
            }
        }

        .middleDiv {
            .game {
                margin-top: 30px;

                .countdown-group-block {
                    display: block;

                    .countdown-box {
                        width: 35px;
                        height: 50px;
                        display: inline-block;
                        margin-right: 10px;
                        margin-top: 40px;
                        background-color: #7dbbf1 !important;
                        color: black;
                        text-align: center;
                        font-weight: bolder;
                        font-size: 18px;
                    }
                }

                .container {
                    div {
                        display: inline-block;
                        position: relative;
                        z-index: 1;

                        .playingCard {
                            width: 45px;
                            margin-right: 15px;
                            cursor: pointer;
                            margin-bottom: 20px;
                        }

                        .blankPlayingCard {
                            width: 45px;
                            margin-right: 15px;
                            background-color: white;
                            cursor: pointer;
                            border: 1px solid black;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .numbers-box {
                width: 50px;
                height: 50px;
                display: inline-block;
                margin-right: 10px;
                margin-top: 40px;
                background-color: #dadada !important;
                color: black;
                text-align: center;
                font-weight: bolder;
                font-size: 2em;
                font-size: 18px;
            }
        }

        .rightDiv {
            text-align: center;
        }
    }
}